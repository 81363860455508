import React, { useEffect, useState } from "react";
import "../../styles/pages/DashBoard/DashBoard.scss";
import {
  adminNavs,
  clientNavs,
  getTransfereesNav,
  consultantNavs,
  supplierNavs,
} from "../../mock-data/dashboard-menu";
import { Avatar, Layout, Space } from "antd";
import CustomMenu from "../../components/Menu/CustomMenu";
import { constantsText } from "../../resources/constantsText";
import ImageComp from "../../components/CustsomImage/ImageComp";
import FormButton from "../../components/FormButton/FormButton";
import DropdownComponent from "../../components/DropDown/DropDown";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { addUser, plusIcon } from "../../resources/SVGImages/dashboard";
import { useAppSelector } from "../../store/hooks";
import {
  clearEmployeeStore,
  resetEmployeeStore,
} from "../../store/AddEmployee/slice";
import { useDispatch } from "react-redux";
import {
  AdminOptions,
  ClientOptions,
  TransfereeOptions,
} from "../Header/HeaderComponent.contants";
import ContactSupport from "../ContactSupport/ContactSupport";
import { useHasRole } from "../../customHooks/useHasRole";
import { setMenu } from "../../store/SidebarMenu/slice";
import logos from "../../resources/logos";

interface siderProps {
  openSider: boolean;
  toggleSider: React.Dispatch<React.SetStateAction<void>>;
}

const Sidebar: React.FC<siderProps> = ({ openSider, toggleSider }) => {
  const { Sider } = Layout;
  const dispatch = useDispatch();

  const [navs, setNavs] = useState<any>([]);
  const navigate = useNavigate();

  const loginDetails = useAppSelector((state) => state?.login.user);
  const siderbarMenu = useAppSelector((state) => state?.siderbarMenu);
  const userInfo = useAppSelector((state) => state?.login.user);
  const userRoles = useAppSelector((state) => state?.login.user?.userRoles);
  let [adminNavList, setAdminNavList] = useState(adminNavs);
  const [openContactSupport, setOpenContactSupport] = useState(false);
  const [openKeys, setOpenKeys] = useState<any>([]);
  const { hasRole } = useHasRole();

  //login

  useEffect(() => {
    getNavs(loginDetails?.role!!);
  }, [loginDetails]);

  const handleSettingClick = (e: any) => {
    if (e.key === "logout") {
      localStorage.clear();
      window.location.href = "/login";
    }

    if (e.key === "support-team") {
      navigate("/support-team");
    }

    if (e.key === "help") {
      navigate("/help");
    }

    if (e.key === "user-profile") {
      navigate("/user-profile");
    }

    if (e.key === "support") {
      setOpenContactSupport(true);
    }

    toggleSider();
  };

  function handleAuthRelocation(): void {
    dispatch(resetEmployeeStore());
    dispatch(clearEmployeeStore());
    navigate("/add-employee");
    if (window.innerWidth < 768) toggleSider();
  }

  const handleMenu = ({ item, key, keyPath, domEvent }: any) => {
    navigate(`${key}`);
    toggleSider();
    dispatch(setMenu([key]));
  };

  useEffect(() => {
    let openKey = "";
    if (siderbarMenu?.[0]) {
      const activeKey = siderbarMenu?.[0];

      navs?.forEach((nav: any) => {
        nav?.children?.forEach((child: any) => {
          if (child.key === activeKey) {
            openKey = nav.key;
          }
        });
      });
    }

    openKey && setOpenKeys([openKey]);
  }, [siderbarMenu, location.pathname, navs]);

  useEffect(() => {
    let adminNavsListCopy = [...adminNavs];

    if (
      hasRole("ROLE_ADMIN") ||
      hasRole("ROLE_CONSULTANT") ||
      hasRole("ROLE_ACCOUNT_MANAGER")
    ) {
      const notificationsKeyExist = adminNavsListCopy[2]?.children?.some(
        (navItem: any) => navItem.key === "notifications",
      );

      if (!notificationsKeyExist) {
        adminNavsListCopy[2]?.children?.push({
          key: "notifications",
          label: "Notifications",
          id: "notifications",
        });
      }
    }

    if (hasRole("ROLE_FINANCE")) {
      const bankInfoUpdatesExists = adminNavsListCopy[2]?.children?.some(
        (navItem: any) => navItem.key === "bank-info-updates",
      );

      if (!bankInfoUpdatesExists) {
        adminNavsListCopy[2]?.children?.push({
          key: "bank-info-updates",
          label: "Bank Info Updates",
        });
      }
    }

    if (hasRole("ROLE_FINANCE") && !hasRole("ROLE_ADMIN")) {
      adminNavsListCopy = adminNavsListCopy.filter(
        (navItem: any) => navItem.key !== "admin",
      );
    }

    if (hasRole("ROLE_CONSULTANT") && !hasRole("ROLE_ADMIN")) {
      adminNavsListCopy = adminNavsListCopy
        .filter((navItem: any) => navItem.key !== "admin")
        .map((navItem: any) => {
          if (navItem.key === "tools") {
            return {
              ...navItem,
              children: navItem?.children?.filter(
                (child: any) => child?.key !== "email-templates",
              ),
            };
          }

          return navItem;
        });
    }

    if (hasRole("OPS_MANAGER") && !hasRole("ROLE_ADMIN")) {
      adminNavsListCopy = adminNavsListCopy
        .map((navItem: any) => {
          if (navItem.key === "admin") {
            return {
              ...navItem,
              children: navItem.children.filter(
                (child: any) => child.key === "sla-configuration",
              ),
            };
          } else {
            return navItem;
          }
        })
        .map((navItem: any) => {
          if (navItem.key === "tools") {
            return {
              ...navItem,
              children: navItem?.children?.filter(
                (child: any) => child?.key !== "email-templates",
              ),
            };
          }

          return navItem;
        });
    }

    if (hasRole("ROLE_ACCOUNT_MANAGER") && !hasRole("ROLE_ADMIN")) {
      adminNavsListCopy = adminNavsListCopy.filter(
        (navItem: any) => navItem.key !== "admin",
      );
    }

    if (!hasRole("ROLE_ADMIN")) {
      adminNavsListCopy = adminNavsListCopy.map((navItem: any) => {
        if (navItem.key === "users") {
          return {
            ...navItem,
            children: navItem.children.filter(
              (child: any) => child.key !== "staff",
            ),
          };
        } else {
          return navItem;
        }
      });
    }

    setAdminNavList(adminNavsListCopy);
  }, [userRoles]);

  useEffect(() => {
    getNavs(userInfo?.role!!);
  }, [adminNavList]);

  const getNavs = (role: string) => {
    if (role === "admin") {
      setNavs(adminNavList);
    } else if (role === "client") {
      setNavs(clientNavs);
    } else if (role === "consultant") {
      setNavs(consultantNavs);
    } else if (role === "transferee") {
      const transfereeNavs = getTransfereesNav(
        loginDetails?.policyType || null,
        userInfo,
      );
      transfereeNavs?.forEach((nav: any) => {
        nav?.children?.forEach((child: any) => {
          if (child.key === "temp-living") {
            child.key = `/temp-living-options/${userInfo?.tlrId}`;
          }
        });
      });
      setNavs(transfereeNavs);
    } else if (role === "supplier") {
      setNavs(supplierNavs);
    }
  };

  const isAdmin = useAppSelector(
    (state) => state?.login?.user_role === "admin",
  );
  const isClient = useAppSelector(
    (state) => state?.login?.user_role === "client",
  );

  return (
    <>
      <Sider className={`dashBoardSlider ${openSider ? "open" : ""}`}>
        <div className="relologoNavBar">
          <div className="desktop">
            <ImageComp
              id="reloLogo"
              className="navigationLogo"
              src={logos.RELO_BLACK}
              alt="Relo logo"
              width={150}
              height={33.87}
              onClick={() => {
                navigate("/");
              }}
              style={{}}
            ></ImageComp>
          </div>
          <div className="dashboardUserBar mobile">
            <Space className="dashboardUserIcon">
              <Avatar
                src={
                  loginDetails?.profilePicS3key
                    ? `${process.env.REACT_APP_IMAGE_PREFIX}${userInfo?.profilePicS3key}`
                    : `${process.env.REACT_APP_CDN_URL}/svgs/user.svg`
                }
                alt="user"
                icon={!userInfo?.profilePicS3key ? <UserOutlined /> : null}
                className="userIcon"
              />
            </Space>

            {isAdmin && (
              <Space className="profileIcon">
                <DropdownComponent
                  className="settingDropdown"
                  triggerText={userInfo?.firstName || "-"}
                  options={AdminOptions}
                  onClick={(e) => handleSettingClick(e)}
                />
              </Space>
            )}
            {isClient && (
              <Space className="profileIcon">
                <DropdownComponent
                  className="settingDropdown"
                  triggerText={userInfo?.firstName || "-"}
                  options={ClientOptions}
                  onClick={(e) => handleSettingClick(e)}
                />
              </Space>
            )}
            {!isClient && !isAdmin && (
              <Space className="profileIcon">
                <DropdownComponent
                  className="settingDropdown"
                  triggerText={userInfo?.firstName || "-"}
                  options={TransfereeOptions}
                  onClick={(e) => handleSettingClick(e)}
                />
              </Space>
            )}
          </div>
          <CloseOutlined
            className="closeSlider mobile"
            onClick={() => {
              toggleSider();
            }}
          />
        </div>

        {userInfo?.role === "admin" || userInfo?.role === "client" ? (
          <div className="authReloBtnDiv">
            <FormButton
              id="addEmployeeBtn"
              color="$selectedItemColor"
              className="authReloBtn"
              icon={addUser}
              btnText={constantsText.authorizeRelocationBtnText}
              onClick={handleAuthRelocation}
            ></FormButton>
          </div>
        ) : null}
        {userInfo?.role &&
        userInfo?.role === "transferee" &&
        loginDetails?.policyType !== "SG_LUMP_SUM" ? (
          <div className="authReloBtnDiv">
            <FormButton
              id="addExpenseBtn"
              color="$selectedItemColor"
              className="authReloBtn"
              icon={plusIcon}
              btnText={constantsText.submitExpesnses}
              disabled={
                !(userInfo?.bankInfoUpdated && userInfo?.allowExpenses) ||
                userInfo?.transfereeStatus?.value === "MOVE_COMPLETE"
              }
              onClick={() => {
                navigate(
                  `/expense-submission-edit/?transfereeId=${userInfo?.id}&type=create`,
                );
                if (window.innerWidth < 768) toggleSider();
              }}
            ></FormButton>
          </div>
        ) : null}

        <CustomMenu
          id="dashboardMenu"
          onClick={handleMenu}
          defaultSelectedKeys={["dashboard"]}
          onOpenChange={(openKeys: any) => {
            setOpenKeys(openKeys);
          }}
          mode="inline"
          className="menuContainer"
          items={navs}
          openKeys={openKeys || []}
          selectedKeys={siderbarMenu || []}
        ></CustomMenu>
      </Sider>

      {openContactSupport && (
        <ContactSupport setOpenContactSupport={setOpenContactSupport} />
      )}
    </>
  );
};

export default Sidebar;
