import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ACTION_GET_COSTESTIMATE_STATE,
  ACTION_GET_COSTESTIMATE_ORIGIN_CITY,
  ACTION_GET_COSTESTIMATE_DESTINATION_CITY,
  ACTION_GET_SHIPPING_TOTAL,
  ACTION_GET_COSTESTIMATE_DEFAULT_CONFIG,
  ACTION_GET_COSTESTIMATE_APPROVERS,
} from "../../../Actions/actions";
import countryStateCity from "../../../services/CountryStateCity/CountryStateCity";
import costEstimatesService from "../../../services/CostEstimates/CostEstimates";

interface intialState {
  data: any;
}

export const initialState: intialState = {
  data: {},
};

export const getState = createAsyncThunk(
  ACTION_GET_COSTESTIMATE_STATE,
  async ({ countryId }: { countryId: string }) => {
    return countryStateCity.getState(countryId);
  },
);

export const getOriginCity = createAsyncThunk(
  ACTION_GET_COSTESTIMATE_ORIGIN_CITY,
  async ({ countryId, stateId }: { countryId: string; stateId: string }) => {
    return countryStateCity.getCity(countryId, stateId);
  },
);

export const getDestinationCity = createAsyncThunk(
  ACTION_GET_COSTESTIMATE_DESTINATION_CITY,
  async ({ countryId, stateId }: { countryId: string; stateId: string }) => {
    return countryStateCity.getCity(countryId, stateId);
  },
);

const getStateFullfield = (state: any, action: any) => {
  state.data.state = action.payload?.data;
};

const getOriginCityFullfield = (state: any, action: any) => {
  state.data.originCity = action.payload?.data;
};

const getDestinationCityFullfield = (state: any, action: any) => {
  state.data.destinationCity = action.payload?.data;
};

export const getShippingTotal = createAsyncThunk(
  ACTION_GET_SHIPPING_TOTAL,
  async (data: any) => {
    return costEstimatesService.getShippingTotal(data);
  },
);

const getShippingTotalFullfield = (state: any, action: any) => {
  state.data.live.shg.shgTotalAPI = action.payload?.data?.total || 0;
  state.data.live.shgTotalAPI = action.payload?.data?.total || 0;
};

export const getDefaultConfig = createAsyncThunk(
  ACTION_GET_COSTESTIMATE_DEFAULT_CONFIG,
  async (data: any) => {
    return costEstimatesService.getDefaultConfig(data);
  },
);

const getDefaultConfigFullfield = (state: any, action: any) => {
  state.data.default = action.payload?.data;
};

export const getApprovers = createAsyncThunk(
  ACTION_GET_COSTESTIMATE_APPROVERS,
  async (data: any) => {
    return costEstimatesService.getApprovers(data);
  },
);

const getApproversFullfield = (state: any, action: any) => {
  state.data.approvers = action.payload?.data;
};

const costEstimatesSlice = createSlice({
  name: "costEstimates",
  initialState,
  reducers: {
    updateLive(state, action) {
      state.data.live = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers(builder): void {
    builder.addCase(getState.fulfilled, getStateFullfield);
    builder.addCase(getOriginCity.fulfilled, getOriginCityFullfield);
    builder.addCase(getDestinationCity.fulfilled, getDestinationCityFullfield);
    builder.addCase(getShippingTotal.fulfilled, getShippingTotalFullfield);
    builder.addCase(getDefaultConfig.fulfilled, getDefaultConfigFullfield);
    builder.addCase(getApprovers.fulfilled, getApproversFullfield);
  },
});

export default costEstimatesSlice.reducer;
export const { updateLive, resetState } = costEstimatesSlice.actions;
